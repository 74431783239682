import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { PromoProvider, usePromo } from "../context/Promo";

const Billing = () => {
  return (
    <PromoProvider>
      <Header />
      <main className="w-full py-7 sm:py-14 max-w-[1367px] mx-auto px-4 xl:px-6 min-h-[calc(100vh_-_82px_-_440px)] md:min-h-[calc(100vh_-_94px_-_300px)]">
        <h2 className="text-3xl font-[600]">Pricing</h2>
        <Comp />
      </main>
      <Footer />
    </PromoProvider>
  );
};

const Comp = () => {
  const { amount } = usePromo();
  return (
    <div className="pl-3">
      <br />
      <br />
      <div className="flex gap-4">
        <div className="flex-1">
          <p className="mb-3 font-semibold text-xl md:text-3xl text-[#3D3D3D]">
            <a
              className="underline hover:underline"
              href="https://www.usps.com/ship/priority-mail-express.htm"
              target="_blank"
            >
              USPS Priority Mail Express®
            </a>
            <br />
            or
            <br />
            <a
              className="underline hover:underline"
              href="https://www.usps.com/ship/priority-mail.htm"
              target="_blank"
            >
              USPS Priority Mail®
            </a>
          </p>
          <p>Base Rate includes 5 pounds.</p>
        </div>

        <p className="text-2xl md:text-5xl flex-1 font-semibold">
          ${amount.USPS} + ${amount.per} per LB
        </p>
      </div>
      <br />
      <br />
      <div className="flex gap-4">
        <div className="flex-1">
          <p className="mb-3 font-semibold text-xl md:text-3xl text-[#3D3D3D]">
            <a
              className="underline hover:underline"
              href="https://www.fedex.com/en-us/shipping/overnight.html#"
              target="_blank"
            >
              FedEx Priority Overnight®
            </a>
          </p>
          <p>Base Rate includes 5 pounds.</p>
        </div>
        <div className="text-2xl md:text-5xl flex-1 font-semibold">
          ${amount.Fedex} + ${amount.per} per LB
        </div>
      </div>

      <br />
      <br />
      <div>
        <p className="font-semibold mb-3">Other</p>
        <div className="max-md:text-sm p-2 mb-3 border border-[#D8D8D8] rounded-lg flex justify-around items-center">
          <span>International Shipping</span>
          <span>Quoted at checkout</span>
        </div>
        <div className="max-md:text-sm p-2 border border-[#D8D8D8] rounded-lg flex justify-around items-center">
          <span>Freight</span>
          <span>Contact for Price</span>
        </div>
      </div>

      <br />
      <a
        href="https://billing.stripe.com/p/login/7sI2bNbcAbyz5uoeUU"
        className="font-medium block mb-2 underline text-[#3C81E9] hover:underline"
        target="_blank"
      >
        View Invoices
      </a>
      <a
        href="https://billing.stripe.com/p/login/7sI2bNbcAbyz5uoeUU"
        className="font-medium underline text-[#3C81E9] hover:underline"
        target="_blank"
      >
        Manage Billing
      </a>
    </div>
  );
};

export default Billing;
