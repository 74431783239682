import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from "react";
import Api from "../api/api";

const USPS = 7;
const Fedex = 18;

const PromoContext = createContext();

export const PromoProvider = ({ children }) => {
  const [data, setData] = useState();

  const amount = useMemo(() => {
    let _data = { USPS: 7, Fedex: 18, per: 1 };
    if (data) {
      if (data.type === "discount") {
        _data = {
          USPS: (USPS - USPS * (data.amount / 100)).toFixed(2),
          Fedex: (Fedex - Fedex * (data.amount / 100)).toFixed(2),
          per: (1 - data.amount / 100).toFixed(2),
        };
      } else if (data.type === "upsell") {
        _data = {
          USPS: (USPS + USPS * (data.amount / 100)).toFixed(2),
          Fedex: (Fedex + Fedex * (data.amount / 100)).toFixed(2),
          per: (1 + data.amount / 100).toFixed(2),
        };
      }
    }
    return _data;
  }, [data]);

  const getData = useCallback(async promo => {
    try {
      const res = await Api.getPromo(promo);
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const promo = localStorage.getItem("shipitor-promo");
    if (promo) {
      getData(promo);
    }
  }, [getData]);

  return <PromoContext.Provider value={{ amount }}>{children}</PromoContext.Provider>;
};

export const usePromo = () => {
  return useContext(PromoContext);
};
