import axios from "axios";
import Cookies from "js-cookie";
import { REACT_APP_KEY_NAME } from "../utils/constants";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const getJwt = () => {
  if (Cookies.get(REACT_APP_KEY_NAME) !== undefined && localStorage.getItem(REACT_APP_KEY_NAME)) {
    return Cookies.get(REACT_APP_KEY_NAME);
  } else {
    Cookies.remove(REACT_APP_KEY_NAME);
    localStorage.removeItem(REACT_APP_KEY_NAME);
  }
};

const setJwt = key => {
  Cookies.set(REACT_APP_KEY_NAME, key, {
    expires: 7,
    path: "/",
    secure: false,
  });
  localStorage.setItem(REACT_APP_KEY_NAME, key);
};

const setAuthHeader = key => {
  axios.defaults.headers.common["x-api-key"] = `${key}`;
  setJwt(key);
};

if (getJwt()) {
  setAuthHeader(getJwt());
}

export default class Api {
  //login
  static login = async data => {
    try {
      const res = await axios.post(`/auth/login`, data);
      return res;
    } catch (error) {
      if ([500, 400, 401, 404]?.includes(error.response?.status)) {
        alert(error.response?.data?.error);
        localStorage.removeItem(REACT_APP_KEY_NAME);
      }
      return error.response;
    }
  };

  //verify login
  static verifyLogin = async data => {
    try {
      const res = await axios.post(`/auth/verifylogin`, data);
      if (res?.data?.success === true) {
        setAuthHeader(res.data?.apikey);
        localStorage.setItem("shipitor-promo", res.data?.promo);
        window.location.href = "/dashboard";
      }

      return res;
    } catch (error) {
      if (error.response?.data?.success === false) {
        localStorage.removeItem(REACT_APP_KEY_NAME);
        alert(error.response?.data?.error);
        window.location.href = "/login";
      }
      return error.response.data;
    }
  };

  //get shipments
  static getShipments = async () => {
    try {
      const res = await axios.get(`/shipments`);
      return res;
    } catch (error) {
      if ([500, 400, 401, 403, 404]?.includes(error.response?.status)) {
        alert(error.response?.data?.error);
        localStorage.removeItem(REACT_APP_KEY_NAME);
        window.location.href = "/";
      }
      return error.response.data;
    }
  };

  //create ups shipment
  static createUPSShipment = async data => {
    try {
      const res = await axios.post(`/create/ups`, data);
      return res;
    } catch (error) {
      alert(error.response?.data?.error);
      if ([401, 403]?.includes(error.response?.status)) {
        localStorage.removeItem(REACT_APP_KEY_NAME);
        window.location.href = "/";
      }
      return error.response.data;
    }
  };

  //create usps shipment
  static createUSPSShipment = async data => {
    try {
      const res = await axios.post(`/create/usps`, data);
      return res;
    } catch (error) {
      alert(error.response?.data?.error);
      if ([401, 403]?.includes(error.response?.status)) {
        localStorage.removeItem(REACT_APP_KEY_NAME);
        window.location.href = "/";
      }
      return error.response.data;
    }
  };

  //create fedex shipment
  static createFedexShipment = async data => {
    try {
      const res = await axios.post(`/create/fedex`, data);
      return res;
    } catch (error) {
      alert(error.response?.data?.error);
      if ([401, 403]?.includes(error.response?.status)) {
        localStorage.removeItem(REACT_APP_KEY_NAME);
        window.location.href = "/";
      }
      return error.response.data;
    }
  };

  //get Intl quote
  static getIntlQuote = async data => {
    try {
      const res = await axios.post(`/international/quote`, data);
      return res;
    } catch (error) {
      alert(error.response?.data?.error);
      if ([400, 401, 403]?.includes(error.response?.status)) {
        localStorage.removeItem(REACT_APP_KEY_NAME);
        window.location.href = "/";
      }
      return error.response.data;
    }
  };

  //accept intl quote
  static acceptIntlQuote = async data => {
    try {
      const res = await axios.post(`/international/accept`, data);
      return res;
    } catch (error) {
      alert(error.response?.data?.error);
      if ([401]?.includes(error.response?.status)) {
        localStorage.removeItem(REACT_APP_KEY_NAME);
        window.location.href = "/";
      }
      return error.response.data;
    }
  };

  //get promo
  static getPromo = async promo => {
    try {
      const res = await axios.get(`/promo/${promo}`);
      return res;
    } catch (error) {
      if ([401]?.includes(error.response?.status)) {
        localStorage.removeItem(REACT_APP_KEY_NAME);
        window.location.href = "/";
      }
      return error.response.data;
    }
  };
}
