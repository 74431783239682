import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import QuoteInfo from "../components/GuestQuote/Intl/QuoteInfo";
import IntlRecipientDetails from "../components/Checkout/International/Recipient";
import PaymentDetails from "../components/Checkout/International/Payment";
import ItemsDetail from "../components/Checkout/International/Items";
import {
  REACT_APP_KEY_NAME,
  REACT_APP_STORAGE_DATA,
  REACT_APP_STORAGE_TYPE,
  getIntlQuoteData,
  initialIntl,
} from "../utils/constants";
import Api from "../api/api";

const QuoteIntl = () => {
  const history = useHistory();
  const location = useLocation();
  const isLoggedIn = Cookies.get(REACT_APP_KEY_NAME) && localStorage.getItem(REACT_APP_KEY_NAME);
  const [data, setData] = useState({ ...initialIntl });
  const [quoteData, setQuoteData] = useState();
  const [show, setShow] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false,
  });

  const handleInputIntlChange = (e, key = null) => {
    const { name, value } = e.target;
    if (name === "zipcode" && value) {
      if (!/^[0-9]+$/.test(value)) return;
    }
    if (key) {
      setData(prev => ({ ...prev, [key]: { ...prev[key], [name]: value } }));
    } else {
      setData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleItemsIntlChange = (e, idx) => {
    const { name, value } = e.target;
    setData(prev => ({
      ...prev,
      items: prev.items?.map((itm, id) => (id === idx ? { ...itm, [name]: value } : itm)),
    }));
  };

  const handleIntlRecDetail = e => {
    e.preventDefault();
    handleStepsShow("step3");
  };

  const handleIntlItems = async e => {
    e.preventDefault();

    const intlQuoteData = getIntlQuoteData(data);
    const res = await Api.getIntlQuote(intlQuoteData);
    if (res?.status === 200) {
      setQuoteData(res?.data);
      handleStepsShow("step4");
    }
  };

  const handleStepsShow = key => {
    const prev = { step1: false, step2: false, step3: false, step4: false };
    setShow({ ...prev, [key]: true });
  };

  const handleCheckout = async e => {
    e.preventDefault();

    if (data?.referal) localStorage.setItem("referral", data?.referal);
    localStorage.setItem(REACT_APP_STORAGE_TYPE, "intl");
    localStorage.setItem(REACT_APP_STORAGE_DATA, JSON.stringify({ quoteId: quoteData?.quoteId }));
    history.push(isLoggedIn ? "/checkout" : "/signup");
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const weight = params.get("weight");
    const type = params.get("type");
    const from = params.get("from");
    const to = params.get("to");

    if (weight && type && from && to) {
      setData(prev => ({
        ...prev,
        shippingFrom: from,
        WeightType: type,
        PackageWeight: Number(weight),
        recipientDetails: { ...prev.recipientDetails, country: to },
      }));
    }
  }, [location.pathname]);

  return (
    <div className={"flex h-[100vh] flex-row-reverse"}>
      {show.step1 && (
        <div className="max-[991px]:hidden w-[60%] bg-secondary">
          <div className="flex w-full items-center justify-center h-[100%]">
            <img
              src="/assets/auth.svg"
              alt="Shipitor"
            />
          </div>
        </div>
      )}
      <div
        className={`max-[991px]:w-full ${show.step1 ? "w-[40%]" : "w-[90%] mx-auto"} h-full overflow-y-auto`}
      >
        <div className="flex w-full items-center justify-center h-[100%] p-4 sm:p-8">
          <div className={`w-full ${show.step1 ? "max-w-[500px]" : ""} h-full`}>
            <Link
              to="/"
              className="block w-fit"
            >
              <img
                className="2xl:w-[200px]"
                src="/assets/logoBlack.svg"
                alt="Shipitor Logo"
              />
            </Link>
            <br />
            <div className="mt-6 relative">
              {!show.step1 && (
                <div className="absolute left-0 top-[-20px] bg-[#D9D9D9] h-1 w-[150px]">
                  <div
                    className={`${show.step2 ? "w-[33%]" : show.step3 ? "w-[66%]" : "w-[90%]"} h-full bg-primary`}
                  ></div>
                </div>
              )}
              {show.step1 && (
                <QuoteInfo
                  data={data}
                  setData={setData}
                  handleChange={handleInputIntlChange}
                  handleSubmit={() => handleStepsShow("step2")}
                />
              )}
              {show.step2 && (
                <IntlRecipientDetails
                  isGuest={true}
                  data={data}
                  setData={setData}
                  handleChange={handleInputIntlChange}
                  handleSubmit={handleIntlRecDetail}
                  handleBack={() => handleStepsShow("step1")}
                />
              )}
              {show.step3 && (
                <ItemsDetail
                  isGuest={true}
                  data={data}
                  setData={setData}
                  handleChange={handleItemsIntlChange}
                  handleSubmit={handleIntlItems}
                  handleBack={() => handleStepsShow("step2")}
                />
              )}
              {show.step4 && (
                <PaymentDetails
                  isGuest={true}
                  data={quoteData}
                  handleSubmit={handleCheckout}
                  handleBack={() => handleStepsShow("step3")}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteIntl;
