import React from "react";
import Footer from "../components/Footer";

const Deal = () => {
  return (
    <>
      <main className="min-h-[calc(100vh_-_344px)]">
        <div className="w-full py-7 px-4 md:px-6 sm:py-14 max-w-[1367px] mx-auto">
          <div className="flex justify-center">
            <img
              className="w-[150px]"
              src="/assets/logoBlack.svg"
              alt="Shipitor Logo"
            />
          </div>
          <br />
          <h2 className="text-center max-md:text-xl text-3xl font-semibold my-7">
            Shipitor Small Business Grants Program
          </h2>
          <p className="text-center max-md:text-base text-xl font-semibold">
            $10,000 in Shipping Credits
          </p>
          <p className="max-md:text-base text-xl font-semibold mt-5">
            About Shipitor:
            <br />
            Shipitor exists to bring big-company shipping advantages to small and emerging growth
            businesses.
            <br />
            <br />
            Deal Terms:
            <br />
            1. This offer is available only to qualified businesses who submit an application by
            July 31, 2024.
            <br />
            2. Value calculated based on retail USPS or FedEx rates.
            <br />
            3. Parcel shipping must be an integral part of the applicant's business model.
            <br />
            4. Customs and duties are still applicable for international shipments and remain the
            responsibility of the business.
            <br />
            5. Shipitor will choose the recipients at its discretion. All decisions are final.
            <br />
            6. Selected businesses must participate in a post-process interview and allow their
            brand to be featured in a case study.
          </p>
          <br />
          <a
            href="https://grv5cu03zdk.typeform.com/to/HBzOOknb"
            target="_blank"
            className="rounded-[5px] bg-primary text-white hover:text-white text-base text-center px-6 py-3 font-[500] block mx-auto w-full max-w-[200px]"
          >
            Apply Now
          </a>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Deal;
