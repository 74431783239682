import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SenderDetails from "../components/Checkout/Domestic/Sender";
import CustomModal from "../layout/Modal";
import RecipientDetails from "../components/Checkout/Domestic/Recipient";
import PackageDetails from "../components/Checkout/Domestic/Package";
import IntlRecipientDetails from "../components/Checkout/International/Recipient";
import Api from "../api/api";
import ShippingSelect from "../components/Checkout/Shipping";
import {
  REACT_APP_STORAGE_TYPE,
  USPS,
  USPS_payload,
  fedex_payload,
  getIntlQuoteData,
  initialDomestic,
  initialIntl,
} from "../utils/constants";
import ItemsDetail from "../components/Checkout/International/Items";
import PaymentDetails from "../components/Checkout/International/Payment";
import { PromoProvider } from "../context/Promo";

const Checkout = () => {
  const history = useHistory();
  const location = useLocation();
  const [isDisable, setIsDisable] = useState(false);
  const [quoteData, setQuoteData] = useState();
  const [type, setType] = useState({ isDoms: false, isIntl: false });
  const [show, setShow] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false,
  });

  const [data, setData] = useState({ ...initialDomestic });
  const [intlData, setIntlData] = useState({ ...initialIntl });

  const handleInputChange = (key, e) => {
    const { name, value } = e.target;
    if (name === "zipcode" && value) {
      if (!/^[0-9]+$/.test(value)) return;
    }
    setData(prev => ({ ...prev, [key]: { ...prev[key], [name]: value } }));
  };

  const handleInputIntlChange = (e, key = null) => {
    const { name, value } = e.target;
    if (name === "zipcode" && value) {
      if (!/^[0-9]+$/.test(value)) return;
    }
    if (key) {
      setIntlData(prev => ({ ...prev, [key]: { ...prev[key], [name]: value } }));
    } else {
      setIntlData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleItemsIntlChange = (e, idx) => {
    const { name, value } = e.target;
    setIntlData(prev => ({
      ...prev,
      items: prev.items?.map((itm, id) => (id === idx ? { ...itm, [name]: value } : itm)),
    }));
  };

  const handleStepsShow = key => {
    const prev = { step1: false, step2: false, step3: false, step4: false };
    setShow({ ...prev, [key]: true });
  };

  const handleSenderDetail = e => {
    e.preventDefault();
    setData(prev => ({
      ...prev,
      sender: {
        ...prev?.sender,
        phone_number: data?.sender?.phone_number?.slice(2),
      },
    }));

    handleStepsShow("step4");
  };

  const handleIntlRecDetail = e => {
    e.preventDefault();
    handleStepsShow("step3");
  };

  const handleIntlItems = async e => {
    e.preventDefault();

    setIsDisable(true);
    const intlQuoteData = getIntlQuoteData(intlData);
    const res = await Api.getIntlQuote(intlQuoteData);
    if (res?.status === 200) {
      setQuoteData(res?.data);
      handleStepsShow("step4");
    }
    setIsDisable(false);
  };

  const handleAcceptQuote = async e => {
    e.preventDefault();
    setIsDisable(true);
    if (!quoteData?.quoteId) return;
    const res = await Api.acceptIntlQuote({ quoteId: quoteData?.quoteId });
    if (res?.status === 200) {
      alert(
        "Label request created. It might take about a business day and we will contact you with your shipping label. ",
      );
      history.push("/dashboard");
    }
    setIsDisable(false);
  };

  const handleCheckout = async e => {
    e.preventDefault();
    setIsDisable(true);

    let _data = { ...data };
    _data["receiver"]["phone_number"] = _data?.receiver?.phone_number?.slice(2);

    let __data = {};
    if (USPS.find(itm => itm.value === data.labelType)) {
      __data = USPS_payload(data);
      const res = await Api.createUSPSShipment(__data);
      if (res?.status === 200) {
        alert("Label created successfully");
        history.push("/dashboard");
      }
    } else if (data.labelType === "fedex") {
      __data = fedex_payload(data);
      const res = await Api.createFedexShipment(__data);
      if (res?.status === 201) {
        alert("FedEx label generated successfully");
        history.push("/dashboard");
      }
    }
    // else if (UPS.find((itm) => itm.value === data.service_type)) {
    //   __data = UPS_payload(data);
    //  const res = await Api.createUPSShipment(__data);
    //  if (res?.status === 200) {
    //    alert("Label created successfully");
    //    history.push("/dashboard");
    //  }
    // }

    setIsDisable(false);
  };

  useEffect(() => {
    const type = localStorage.getItem(REACT_APP_STORAGE_TYPE);

    if (type) {
      if (type === "domestic") {
        setType({ isIntl: false, isDoms: true });
      } else if (type === "intl") {
        setType({ isDoms: false, isIntl: true });
      }
    }
  }, [location.pathname]);

  return (
    <PromoProvider>
      <Header />
      <main className="w-full py-7 sm:py-14 max-w-[1367px] mx-auto px-4 xl:px-6 min-h-[calc(100vh_-_270px_-_440px)] md:min-h-[calc(100vh_-_94px_-_300px)]">
        <div className="flex w-full items-center flex-wrap justify-between gap-4 mb-5 min-h-[400px]">
          <CustomModal
            title={`${
              (type?.isDoms && show.step1) ||
              (type?.isIntl && (show.step1 || show.step2 || show.step3))
                ? "Shipment Details"
                : show.step4 && type.isIntl
                  ? "Payment Details"
                  : show.step2
                    ? "Package Detail"
                    : show.step3
                      ? "Sender Address"
                      : "Recipient Address"
            }`}
            isClose={false}
            onHide={() => {}}
          >
            {!show.step1 && (
              <div className="absolute right-0 top-[-44px] md:top-[-52px] bg-[#D9D9D9] h-1 w-[150px]">
                <div
                  className={`${show.step2 ? "w-[33%]" : show.step3 ? "w-[66%]" : "w-[90%]"} h-full bg-primary`}
                ></div>
              </div>
            )}
            {show.step1 && (
              <ShippingSelect
                type={type}
                setType={setType}
                handleSubmit={() => handleStepsShow("step2")}
              />
            )}
            {type?.isDoms && (
              <>
                {show.step2 && (
                  <PackageDetails
                    data={data}
                    setData={setData}
                    handleSubmit={() =>
                      data?.labelType === "fedex"
                        ? handleStepsShow("step4")
                        : handleStepsShow("step3")
                    }
                    handleBack={() => handleStepsShow("step1")}
                  />
                )}
                {show.step3 && (
                  <SenderDetails
                    data={data?.sender}
                    setData={setData}
                    handleChange={e => handleInputChange("sender", e)}
                    handleSubmit={handleSenderDetail}
                    handleBack={() => handleStepsShow("step2")}
                  />
                )}
                {show.step4 && (
                  <RecipientDetails
                    isDisable={isDisable}
                    data={data?.receiver}
                    setData={setData}
                    labelType={data?.labelType}
                    handleChange={e => handleInputChange("receiver", e)}
                    handleSubmit={handleCheckout}
                    handleBack={() =>
                      data?.labelType === "fedex"
                        ? handleStepsShow("step2")
                        : handleStepsShow("step3")
                    }
                  />
                )}
              </>
            )}
            {type?.isIntl && (
              <>
                {show.step2 && (
                  <IntlRecipientDetails
                    data={intlData}
                    setData={setIntlData}
                    handleChange={handleInputIntlChange}
                    handleSubmit={handleIntlRecDetail}
                    handleBack={() => handleStepsShow("step1")}
                  />
                )}
                {show.step3 && (
                  <ItemsDetail
                    data={intlData}
                    setData={setIntlData}
                    handleChange={handleItemsIntlChange}
                    handleSubmit={handleIntlItems}
                    handleBack={() => handleStepsShow("step2")}
                  />
                )}
                {show.step4 && (
                  <PaymentDetails
                    data={quoteData}
                    handleSubmit={handleAcceptQuote}
                    handleBack={() => handleStepsShow("step3")}
                  />
                )}
              </>
            )}
          </CustomModal>
        </div>
      </main>
      <Footer />
    </PromoProvider>
  );
};

export default Checkout;
