import Login from "../pages/login";
import Register from "../pages/register";
import Dashboard from "../pages/dashboard";
import Checkout from "../pages/checkout";
import VerifyLogin from "../pages/verifyLogin";
import Home from "../pages/home";
import Payment from "../pages/payment";
import Privacy from "../pages/privacy";
import Term from "../pages/term";
import QuoteDomestic from "../pages/quoteDomestic";
import QuoteIntl from "../pages/quoteIntl";
import Billing from "../pages/billing";
import Deal from "../pages/deal";

export const ROUTES = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/payment",
    component: Payment,
  },
  {
    path: "/deal",
    component: Deal,
  },
  {
    path: "/privacy",
    component: Privacy,
  },
  {
    path: "/terms",
    component: Term,
  },
  {
    path: "/tos",
    component: Term,
  },
  {
    path: "/quote-domestic",
    component: QuoteDomestic,
  },
  {
    path: "/quote-intl",
    component: QuoteIntl,
  },
];

export const PROTECTED_ROUTES = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/billing",
    component: Billing,
  },
  {
    path: "/checkout",
    component: Checkout,
  },
];

export const PUBLIC_ROUTES = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/signup",
    component: Register,
  },
  {
    path: "/verifylogin",
    component: VerifyLogin,
  },
];
